import React from 'react';
import classNames from 'classnames/bind';
import styles from './Pushkin.module.scss';
import Header from "../../components/Header/Header";
import pushkinStrips from '../../assets/images/Header/pushkin-strips.svg';
import pushkinBg from '../../assets/images/Pushkin/pushkin-main.jpg';
import {NavLink} from "react-router-dom";

const cn = classNames.bind(styles);

const Pushkin = () => {

    return (
        <div className={cn('wrapper')}>
            <Header background="#E6215A" text={"Квиз: Стихи, долги и карты.\n Что вы знаете лучше Пушкина?"}
                    strips={pushkinStrips}/>
            <div className={cn('content')}>
                <img src={pushkinBg} alt="main-img"/>
                <div className={cn('content-wrapper')}>
                    <p className={cn('first-text')}>
                        В сентябре выходят первые серии образовательного видеоподкаста для подростков и молодежи «Пушкин
                        знает!» – совместного проекта НИФИ Минфина России и Почта Банка. В нем финансовые неудачи поэта
                        будут оценены с помощью всего арсенала современных знаний о деньгах.
                    </p>
                    <p className={cn('second-text')}>
                        Проверьте, хорошо ли вы ориентируетесь в финансовой культуре пушкинской эпохи и сегодняшнего
                        дня, с помощью небольшого теста.
                    </p>
                    <NavLink to="/pushkin/1" className={cn('btn')}>НАЧАТЬ</NavLink>
                </div>
            </div>
        </div>

    );
};

export default Pushkin;