import React, {useEffect} from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import MainPage from "./pages/MainPage/MainPage";
import BestPractices from "./pages/BestPractices/BestPractices";
import Pushkin from "./pages/Pushkin/Pushkin";
import PushkinQuestion from "./pages/PushkinQuestion/PushkinQuestion";
import PushkinResult from "./pages/PushkinResult/PushkinResult";
import FinanceQuizzes from "./pages/FinanceQuizzes/FinanceQuizzes";
import FinanceQuizz from "./pages/FinanceQuizz/FinanceQuizz";
import BestProjects from "./pages/BestProjects/BestProjects";
import BestPracticesPage from "./pages/BestPracticesPage/BestPracticesPage";
import Wheel from "./pages/Wheel/Wheel";
import FinanceHabits from "./pages/FinanceHabits/FinanceHabits";
import FinanceHabitsQuestion from "./pages/FinanceHabitsQuestion/FinanceHabitsQuestion";
import Tracker from "./pages/Tracker/Tracker";
import TrackerResult from "./pages/TrackerResult/TrackerResult";
import TrackerFirstAdvices from "./pages/TrackerAdvices/TrackerFirstAdvices";
import TrackerSecondAdvices from "./pages/TrackerAdvices/TrackerSecondAdvices";
import FinanceHabitsResult from "./pages/FinanceHabitsResult/FinanceHabitsResult";
import TrackerRules from "./pages/TrackerRules/TrackerRules";

const App = () => {

    // useEffect(() => {
    //     document.addEventListener('contextmenu', event => event.preventDefault());
    //     return document.removeEventListener('contextmenu', event => event.preventDefault());
    // }, [])


    return (
        <div className="app-wrapper">
            <Routes>
                <Route path="/" element={<MainPage/>} />
                <Route path="/best-projects" element={<BestProjects />} />
                <Route path="/pushkin" element={<Pushkin />} />
                <Route path="/pushkin/:questionNumber" element={<PushkinQuestion />} />
                <Route path="/pushkin/result" element={<PushkinResult />} />
                <Route path="/finance-quizzes" element={<FinanceQuizzes />} />
                <Route path="/finance-quizzes/:quizzNumber" element={<FinanceQuizz />} />
                <Route path="/best-practices" element={<BestPractices />} />
                <Route path="/best-practices/:pageNumber" element={<BestPracticesPage />} />
                <Route path="/tracker" element={<Tracker />} />
                <Route path="/tracker/wheel" element={<Wheel />} />
                <Route path="/tracker/result" element={<TrackerResult />} />
                <Route path="/tracker/advices-1" element={<TrackerFirstAdvices />} />
                <Route path="/tracker/advices-2" element={<TrackerSecondAdvices />} />
                <Route path="/tracker/rules" element={<TrackerRules />} />
                <Route path="/finance-habits" element={<FinanceHabits />} />
                <Route path="/finance-habits/:questionNumber" element={<FinanceHabitsQuestion />} />
                <Route path="/finance-habits/result" element={<FinanceHabitsResult />} />
            </Routes>
        </div>
    );
};

export default App;
