import React, {useEffect, useState, useContext} from 'react';
import classNames from 'classnames/bind';
import styles from './PushkinResult.module.scss';
import {NavLink} from 'react-router-dom';
import Header from "../../components/Header/Header";
import {PushkinContext} from "../../context/pushkinTestContext";
import resultBg from '../../assets/images/Pushkin/result-bg.jpg';
import pushkinStrips from '../../assets/images/Header/pushkin-strips.svg';
import {pushkinResults} from "../../data/pushkin";

const cn = classNames.bind(styles);

const PushkinResult = () => {
    const [resultData, setResultData] = useState(null);
    const {result, clearResult} = useContext(PushkinContext);

    useEffect(() => {
        if (result > 4) setResultData(pushkinResults[0]);
        if (result > 2 && result < 5) setResultData(pushkinResults[1]);
        if (result < 3) setResultData(pushkinResults[2]);
    }, [])

    return (
        <div className={cn('wrapper')}>
            <Header background="#E6215A" text={"Квиз: Стихи, долги и карты. \n Что вы знаете лучше Пушкина?"}
                    strips={pushkinStrips}/>
            <div className={cn('content')}>
                <img src={resultBg} alt=""/>
                <div className={cn('text-wrapper')}>
                    <div className={cn('text-result')}>Результаты:</div>
                    <h2 className={cn('title')}>{resultData?.title}</h2>
                    <p className={cn('bold-text')}>{resultData?.boldText}</p>
                    <p className={cn('first-text')}>{resultData?.text}</p>
                    <p className={cn('second-text')}>
                        И не забудьте показать этот видеоподкаст детям-подросткам, мы уверены – они оценят!
                    </p>
                    <NavLink className={cn('btn')} to="/" onClick={() => clearResult()}>ЗАВЕРШИТЬ</NavLink>
                </div>
            </div>
        </div>

    );
};

export default PushkinResult;